<template>
  <main>
    <section class="js-page page join">
      <div class="page-content" v-if="project">
        <div class="searched-bar">
          <div class="searched-sort">
            <router-link to="/projects"> Projects </router-link> >
            <span class="post-time">{{ project.name }}</span
            ><br />
            <p></p>
          </div>
        </div>
        <div class="projectHeader darkBg">
          <Button
            v-if="project.jobs.filter((job) => job.status == -1).length != 0"
            bgColor="var(--color-light-blue)"
            txtColor="#ffffff"
            shadow="var(--shadow-dark)"
            @click="$router.push(`/viewer?jobId=${latestJobID(project).id}`)"
            style="border-radius: 12px; float: right"
            >View Model <Icon>east</Icon></Button
          >
          <Button
            v-else
            class="opacity-5"
            bgColor="var(--color-light-blue)"
            txtColor="#ffffff"
            shadow="var(--shadow-dark)"
            style="border-radius: 12px; float: right"
            >No model simulated !</Button
          >
          <div
            class="projectLocation"
            :class="{ 'alert-state': !project.location }"
            @click="showLocation = true"
          >
            <span class="material-icons">place</span>
            <p v-if="project.location">
              {{ project.location.lng.toFixed(3) }},
              {{ project.location.lat.toFixed(3) }}
            </p>
            <p v-else>SET PROJECT LOCATION</p>
          </div>

          <div
            class="titleWrapper"
            :class="{ 'edit-icon': project.name == 'my project name' }"
          >
            <input
              class="projectTitle"
              @blur="updateproject('name')"
              v-model="project.name"
            /><Icon>border_color</Icon>
          </div>
          <div
            class="titleWrapper"
            :class="{
              'edit-icon': project.number == 'my project reference number',
            }"
          >
            <input
              class="projectInfo"
              @blur="updateproject('number')"
              v-model="project.number"
            /><Icon>border_color</Icon>
          </div>
          <div class="projectInfo">
            <!-- <p>{{ project.number }}</p> -->
          </div>

          <div class="projectSelections">
            <div class="custom-select access">
              <select
                name="accessType"
                id=""
                @change="updateproject('accessType')"
                v-model="project.accessType"
              >
                <option :value="0">&#128274; Private</option>
                <option :value="2">&#128275; Public</option>
              </select>
            </div>
            <div class="custom-select type">
              <select
                name="category"
                id=""
                @change="updateproject('category')"
                v-model="project.category"
              >
                <option value="">Mixed</option>
                <option value="Residential">Residential</option>
                <option value="School">School</option>
                <option value="Commercial">Commercial</option>
                <option value="Office">Office</option>
              </select>
            </div>
          </div>
        </div>

        <ul class="infoBoxes">
          <!-- <li class="element">
            <span>PASSED ROOMS</span>
            <p>22%</p>
            <Icon class="increase">north_east</Icon>
            <strong>+3%</strong> since previous
          </li> -->
          <!-- <li class="element">
            <span>INFO</span>
            <p class="time">
              <span>Created:</span> {{ project.createdDate.split("T")[0] }}
            </p>
          </li> -->

          <li class="element" v-if="remainingIterations > 0">
            <Button
              bgColor="var(--color-dark-green)"
              txtColor="#ffffff"
              shadow="var(--shadow-green)"
              style="border-radius: 12px"
              @click="newJob()"
              ><Icon>loop</Icon>
              <div>
                <div class="btn-title">Use simulation</div>
                <div class="btn-description">
                  {{ remainingIterations }} remaining
                </div>
              </div>
            </Button>
          </li>
          <li class="element" v-else-if="project.jobs.length > 1">
            <Button
              bgColor="var(--color-blue)"
              txtColor="#ffffff"
              shadow="var(--shadow-green)"
              style="border-radius: 12px"
              @click="newJob()"
              ><Icon>add</Icon> Buy simulation</Button
            >
          </li>
          <li class="element" v-else>
            <Button
              class="opacity-1 no-pointer"
              bgColor="var(--color-bg-dark)"
              txtColor="#ffffff"
              shadow="var(--shadow-green)"
              style="border-radius: 12px"
              ><Icon>add</Icon> Buy simulation</Button
            >
          </li>
          <li class="element">
            <Button
              class="opacity-1 no-pointer"
              bgColor="var(--color-bg-dark)"
              txtColor="#ffffff"
              shadow="var(--shadow-green)"
              style="border-radius: 12px"
              ><Icon>post_add</Icon> Buy report</Button
            >
          </li>
          <!-- <li class="element">
            <Button
              bgColor="var(--color-dark-green)"
              txtColor="#ffffff"
              shadow="var(--shadow-green)"
              style="border-radius: 12px"
              ><Icon>content_copy</Icon>
              <div>
                <div class="btn-title">Use report</div>
                <div class="btn-description">2 remaining</div>
              </div>
            </Button>
          </li> -->
          <li class="element">
            <Button
              class="opacity-1 no-pointer"
              bgColor="var(--color-bg-dark)"
              txtColor="#ffffff"
              shadow="var(--shadow-green)"
              style="border-radius: 12px"
              ><Icon>group_add</Icon> Request meeting</Button
            >
          </li>
          <!-- <li class="element">
            <Button
              bgColor="var(--color-dark-green)"
              txtColor="#ffffff"
              shadow="var(--shadow-green)"
              style="border-radius: 12px"
              ><Icon>group_add</Icon>
              <div>
                <div class="btn-title">Book meeting</div>
                <div class="btn-description">1 remaining</div>
              </div>
            </Button>
          </li> -->
        </ul>

        <div class="searched-bar">
          <!-- <div class="searched-show" v-if="project.jobs.length > 0">
            Showing {{ project.jobs.length }} simulation(s)
          </div> -->

          <!-- <div class="searched-sort">
            Sort by: <span class="post-time">Newest Project</span
            ><span class="menu-icon">▼</span>
          </div> -->
          <!-- <h3 class="text-center" v-else>
            This project has no simulations yet
          </h3> -->
        </div>

        <div class="iteration-wrapper">
          <!-- <div class="iteration-card new-iteration" @click="newJob()">
            New iteration
          </div> -->
          <div
            class="iteration-card"
            v-for="type in [...project.jobs].reverse()"
            :key="type"
          >
            <div class="iteration-card-wrapper">
              <div
                @click.="
                  currentJob == type.id
                    ? (currentJob = '')
                    : (currentJob = type.id)
                "
                class="iteration-card-top"
              >
                <!-- <Icon>chevron_right</Icon> -->
                <div>
                  <div class="iteration-card-title pointer">
                    <!-- {{ type.name }} -->
                    <input
                      class="iteration-card-title"
                      @blur="updatejob('name', type.name, type.id)"
                      v-model="type.name"
                    />
                    <div class="status iteration-detail-buttons">
                      <select
                        v-if="store.state.toggle.dev"
                        class="search-buttons detail-button"
                        @change="
                          updatejob('status', $event.target.value, type._id)
                        "
                      >
                        <option :value="0" selected>New</option>
                        <option :value="5">Order received</option>
                        <option :value="6">Converting...</option>
                        <option :value="7">Quality checking...</option>
                        <option :value="8">Simulating...</option>
                        <option :value="9">Quality checking...</option>
                        <option :value="-1">Done</option>
                      </select>
                    </div>
                  </div>
                  <!-- <input
                  class="iteration-card-subtitle"
                  @blur="updatejob('name', type.name, type.id)"
                  v-model="type.name"
                /> -->
                  <p class="time">
                    <span>Created:</span> {{ type.createdDate.split("T")[0] }}
                  </p>
                </div>

                <div class="flex justify-end items-center">
                  <button class="status-button" v-on:click.stop>
                    {{ $t(`simStatus.${type.status}`) }}
                  </button>
                </div>

                <div class="iteration-card-buttons flex justify-end">
                  <!-- <button
                  class="search-buttons card-buttons action"
                  @click="$router.push(`/fileupload/job/${type.id}`)"
                >
                  <Icon>settings</Icon> Settings
                </button> -->
                  <button
                    v-on:click.stop
                    class="search-buttons card-buttons action dark"
                    @click="$router.push(`/viewer?jobId=${type.id}`)"
                    v-if="type.staus == -1 || hasPerm('system.admin')"
                  >
                    <Icon>east</Icon> View
                  </button>

                  <!-- <button
                  v-else-if="type.status == 'payup'"
                  class="search-buttons card-buttons action"
                  @click="$router.push(`/fileupload/job/${type.id}`)"
                >
                  Payup
                </button>

                <button
                  v-else-if="type.status == 'confirm'"
                  class="search-buttons card-buttons action"
                  @click="$router.push(`/viewer?jobId=${type.id}`)"
                >
                  Confirm
                </button> -->
                </div>
              </div>
              <FileUpload
                v-if="type.id == currentJob && type.status == 0"
                :id="type.id"
                :pid="project._id"
                :jobStatus="type.status"
                :included="remainingIterations > 0"
              />
              <div
                class="payment-success"
                v-if="type.id == currentJob && type.status != 0"
              >
                <div class="check-container">
                  <div class="check-background">
                    <svg
                      viewBox="0 0 65 51"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7 25L27.3077 44L58.5 7"
                        stroke="white"
                        stroke-width="13"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <div class="check-shadow"></div>
                </div>
                <div class="payment-text">Your order is paid!</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
  <Footer></Footer>
  <locationPicker
    v-if="showLocation"
    @close="showLocation = false"
    @save="
      ;(project.location = $event),
        updateproject('location'),
        (showLocation = false)
    "
    :loc="project.location"
  />
</template>

<script >
import { usePerms } from "@/logic/usePerms"
import axios from "axios"
import { computed, defineComponent, ref } from "vue"
import { useStore } from "vuex"
import Button from "../components/Button.vue"
import Footer from "../components/footer.vue"
import Icon from "../components/Icon.vue"
import FileUpload from "../views/Fileupload.vue"
import locationPicker from "../components/locationPicker.vue"

export default defineComponent({
  name: "Dashboard",
  components: {
    Button,
    Icon,
    Footer,
    FileUpload,
    locationPicker,
  },
  props: {
    pid: String,
    jid: String,
  },
  setup(props) {
    const store = useStore()
    const { hasPerm, checkToken } = usePerms()

    const token = computed(() => store.getters["auth/token"])
    let oproject = {}
    const project = ref()
    let currentJob = ref(props.jid ? props.jid : "")
    // const url = "http://localhost:8085"
    const url = "https://api.otonomi.no"
    const showLocation = ref(false)
    const loadProjects = async () => {
      if (!store.getters["auth/isLogedIn"]) {
        store.commit("setModal", 2)
        return
      }
      await checkToken()

      axios({
        baseURL: url,
        url: "/project/" + props.pid,
        data: {},
        method: "GET",
        headers: {
          Authorization: "Bearer " + token.value,
        },
      })
        .then((resp) => {
          console.log(resp)
          oproject = resp.data
          project.value = resp.data
        })
        .catch((err) => {
          console.log(err)
        })
    }

    const latestJobID = (project) => {
      const jobs = project.jobs.filter((v) => {
        return v.status == -1
      })
      return jobs.reverse()[0]
    }

    const inciterations = computed(() => {
      let iterations = 0
      if (project.value.orders) {
        for (let i = 0; i < project.value.orders.length; i++) {
          const order = project.value.orders[i]
          iterations += order.incIterations
        }
      }
      if (project.value.incIterations) {
        iterations += project.value.incIterations
      }

      return iterations
    })

    const remainingIterations = computed(() => {
      console.log(inciterations.value, project.value.jobs)
      return (
        inciterations.value -
        project.value.jobs.filter((job) => job.status != 0).length
      )
    })

    const allowednewiteration = computed(() => {
      if (hasPerm("system.admin") || hasPerm("otonomi.admin")) {
        return true
      } else {
        return project.value.jobs.length < inciterations + 1
      }
    })

    const updateproject = async (property, e) => {
      if (!store.getters["auth/isLogedIn"]) {
        store.commit("setModal", 2)
        return
      }
      await checkToken()

      axios({
        baseURL: url,
        url: "/project/" + project.value.id,
        data: {
          [property]: project.value[property],
        },
        method: "PATCH",
        headers: {
          Authorization: "Bearer " + token.value,
        },
      })
        .then((resp) => {
          console.log(resp)
        })
        .catch((err) => {
          console.log(err)
        })
    }
    const newJob = async () => {
      if (!store.getters["auth/isLogedIn"]) {
        store.commit("setModal", 2)
        return
      }
      await checkToken()

      axios({
        baseURL: url,
        url: "/job/" + project.value.id,
        data: {},
        method: "POST",
        headers: {
          Authorization: "Bearer " + token.value,
        },
      })
        .then((resp) => {
          console.log(resp)

          project.value = resp.data
          currentJob = resp.data.jobs[resp.data.jobs.length]._id
          // projects.value = p
        })
        .catch((err) => {
          console.log(err)
        })
    }
    const updatejob = async (property, value, jid) => {
      if (!store.getters["auth/isLogedIn"]) {
        store.commit("setModal", 2)
        return
      }
      await checkToken()
      console.log(property, value, jid)
      axios({
        baseURL: url,
        url: "/job/" + jid,
        data: {
          [property]: value,
        },
        method: "PATCH",
        headers: {
          Authorization: "Bearer " + token.value,
        },
      })
        .then((resp) => {
          console.log(resp)
        })
        .catch((err) => {
          console.log(err)
        })
    }

    loadProjects()
    return {
      store,
      project,
      remainingIterations,
      updateproject,
      updatejob,
      newJob,
      latestJobID,
      currentJob,
      inciterations,
      allowednewiteration,
      showLocation,
      hasPerm,
    }
  },
})
</script>

<style lang="scss" scoped>
// @import "../css/join.scss";
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; /* Remove default arrow */
  // background-image: url(...); /* Add custom arrow */
  border: 0px;
  outline: 0px;
  color: var(--color-blue);
  font-size: 0.8rem;
  font-weight: 800;
  padding: 6px 8px;
  border-radius: 4px;
}
.payment-success {
  width: calc(100% + 60px);
  height: 300px;
  margin: 0 -30px -30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // background: linear-gradient(
  //   90deg,
  //   rgba(157, 212, 94, 1) 0%,
  //   rgba(60, 158, 68, 1) 100%
  // );
  background: linear-gradient(
    90deg,
    var(--color-medium-green) 0%,
    var(--color-dark-green) 100%
  );

  .payment-text {
    font-size: 1.2rem;
    font-weight: 600;
    color: white;
  }

  .check-container {
    width: 6.25rem;
    height: 7.5rem;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-between;

    .check-background {
      width: 100%;
      height: calc(100% - 1.25rem);
      background: #03583a;
      box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset,
        0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
      transform: scale(0.84);
      border-radius: 50%;
      animation: animateContainer 0.75s ease-out forwards 0.75s;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;

      svg {
        width: 65%;
        transform: translateY(0.25rem);
        stroke-dasharray: 80;
        stroke-dashoffset: 80;
        animation: animateCheck 0.35s forwards 1.25s ease-out;
      }
    }

    .check-shadow {
      bottom: calc(-15% - 5px);
      left: 0;
      border-radius: 50%;
      background: radial-gradient(
        closest-side,
        rgba(73, 218, 131, 1),
        transparent
      );
      animation: animateShadow 0.75s ease-out forwards 0.75s;
    }
  }
}
.page-content {
  width: 800px;
  margin: 200px auto 0;
}
.progress-bar {
  height: 4px;
  width: 100%;
  overflow: hidden;
  border-radius: 2px;
  background-color: #dadff3;
  margin: 6px 0;

  .progress {
    height: 100%;
    width: 40%;
    background-color: #4bc0dd;
    display: block;
  }
}

.searched-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-animation: slideY 0.6s both;
  animation: slideY 0.6s both;

  .searched-show {
    font-size: 19px;
    font-weight: 600;
  }
  .searched-sort {
    font-size: 14px;
    color: var(--color-text-light);
    .post-time {
      font-weight: 600;
      color: var(--color-text-light);
    }
    .menu-icon {
      font-size: 9px;
      color: var(--color-text-light);
      margin-left: 6px;
    }
  }
}

.projectSelections {
  display: flex;
  align-items: center;
  width: fit-content;
  .custom-select {
    position: relative;
    margin-right: 20px;
    &::after {
      position: absolute;
      right: 16px;
      top: 0.5rem;
      color: white;
      font-size: 0.6rem;
      pointer-events: none;
    }
    &.access::after {
      content: "ACCESS ▼";
    }
    &.type::after {
      content: "TYPE ▼";
    }
    select {
      position: relative;
      appearance: none;
      background: #2f276d;
      border: none;
      border-radius: 999px;
      padding: 6px 70px 6px 18px;
      font-weight: 600;
      font-size: 0.9rem;
      color: var(--color-light-blue);
      margin: 0;
      cursor: pointer;

      &:hover {
        box-shadow: var(--shadow-dark);
      }

      &:focus {
        outline: none;
      }
    }
  }
}

.iteration-wrapper {
  position: relative;
  font-size: 1rem;
  margin: 2rem 1.7rem 3rem 1.7rem;
  display: grid;
  grid-template-columns: repeat(1, 1fr);

  .iteration-card {
    position: relative;
    text-align: left;
    padding: 10px 0;

    &:first-child {
      padding-top: 0;
    }

    .iteration-card-wrapper {
      position: relative;
      background: white;
      border-radius: 12px;
      box-shadow: var(--shadow-light);
      overflow: hidden;
      transition: all 0.3 ease-in-out;

      &:hover {
        box-shadow: var(--shadow-dark);
      }

      .iteration-card-top {
        display: grid;
        grid-template-columns: 2fr 1fr 0.8fr;
        padding: 20px 30px;
      }

      .status-button {
        text-align: right;
        border-radius: 9px;
        background: var(--color-bg);
        padding: 4px 8px;
        font-size: 0.9rem;
        font-weight: 600;
        color: var(--color-dark-blue);
      }
    }

    input {
      background: white;
      border: none;
      padding: 0;
      font-size: 1rem;
      font-weight: 700;
    }

    .iteration-card-title {
      font-size: 1rem;
      font-weight: 700;
      position: relative;
      width: fit-content;

      .status {
        position: absolute;
        text-align: right;
        top: -14px;
        left: 100%;
        border-radius: 999px;
        font-size: 1rem;
        cursor: pointer;

        select {
          background: transparent;

          &::before {
            position: absolute;
            top: 0;
            left: 0;
            content: "STATUS:";
          }
        }

        button {
          font-weight: 800;
          font-size: 0.8rem;
          background: transparent;
          color: var(--color-blue);
        }
      }
    }
    .time {
      margin: 5px 0 0;
      font-size: 0.75rem;
      color: var(--color-text-light);
      span {
        font-weight: 600;
      }
    }
    .iteration-card-subtitle {
      width: 50%;
    }
  }

  .card-buttons {
    margin: 5px 0;
    padding: 7px 11px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 0.8rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    .material-icons {
      font-size: 0.8rem;
    }
    &.dark {
      background: var(--color-bg-dark);
      color: white;
    }
  }

  .new-iteration {
    width: fit-content;
    color: var(--color-medium-green);
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 2rem;
    cursor: pointer;
    box-sizing: border-box;

    &::before {
      position: absolute;
      top: -0.2rem;
      left: -0.95rem;
      content: "+";
      font-weight: 700;
      font-size: 1.4rem;
      color: white;
      text-align: center;
      line-height: 1.7rem;
      width: 1.9rem;
      height: 1.9rem;
      border-radius: 100%;
      background: var(--color-medium-green);
    }

    &:hover {
      color: var(--color-text);

      &::before {
        background: var(--color-text);
      }
    }
  }
}

@keyframes animateContainer {
  0% {
    opacity: 0;
    transform: scale(0);
    box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset,
      0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
  }
  25% {
    opacity: 1;
    transform: scale(0.9);
    box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset,
      0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
  }
  43.75% {
    transform: scale(1.15);
    box-shadow: 0px 0px 0px 43.334px rgba(255, 255, 255, 0.25) inset,
      0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
  }
  62.5% {
    transform: scale(1);
    box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset,
      0px 0px 0px 21.667px rgba(255, 255, 255, 0.25) inset;
  }
  81.25% {
    box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset,
      0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset;
  }
  100% {
    opacity: 1;
    box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset,
      0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset;
  }
}

@keyframes animateCheck {
  from {
    stroke-dashoffset: 80;
  }
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes animateShadow {
  0% {
    opacity: 0;
    width: 100%;
    height: 15%;
  }
  25% {
    opacity: 0.25;
  }
  43.75% {
    width: 40%;
    height: 7%;
    opacity: 0.35;
  }
  100% {
    width: 85%;
    height: 15%;
    opacity: 0.25;
  }
}
</style>
