<template>
  <div class="container">
    <div class="blur" @click="close"></div>

    <div class="modal">
      <Icon class="close" @click="close">close</Icon>
      <h2 class="header">
        Pick your project location for an accurate simulation.
      </h2>

      <div class="details">
        <div class="form-group">
          <span class="label"><Icon>location_on</Icon></span>
          <input
            class="form-field"
            type="text"
            v-model="adress"
            placeholder="Adress"
            @keypress.enter="lookupaddress"
          />
        </div>

        <GoogleMap
          api-key="AIzaSyAAE8RL9uXWO0jb-zUnR4WI3g-j7kR9gIY"
          style="width: 100%; height: 500px"
          :center="center"
          :zoom="zoom"
          @click="mapclick"
        >
          <Marker :options="{ position: center }" />
        </GoogleMap>
        <!-- {{ center }}{{ center.lat.toFixed(3) }},{{ center.lng.toFixed(3) }} -->

        <Button
          bgColor="var(--color-bg-dark)"
          txtColor="#ffffff"
          shadow="var(--shadow-dark)"
          @click="$emit('save', center)"
          >SAVE LOCATION
          <br />
          {{ center.lng.toFixed(3) }},{{ center.lat.toFixed(3) }}
        </Button>
      </div>
    </div>
    <!-- </div> 'info@trees.no', '1234admin' -->
    <!-- </transition> -->
  </div>
</template>
<script >
import { defineComponent, ref, computed, onMounted } from "vue"
import { useRoute } from "vue-router"
import { useStore } from "vuex"
import Icon from "./Icon.vue"
import Button from "./Button.vue"
import axios from "axios"
import { useGtag } from "vue-gtag-next"
import { GoogleMap, Marker } from "vue3-google-map"
import { usePerms } from "@/logic/usePerms"

const apiurl = "https://api.otonomi.no"
// const apiurl = "http://localhost:8085"

export default defineComponent({
  components: { Icon, Button, GoogleMap, Marker },
  props: { location: JSON },

  setup(props, { emit }) {
    const { hasPerm, checkToken } = usePerms()
    const store = useStore()
    const route = useRoute()
    const token = computed(() => store.getters["auth/token"])

    const { event } = useGtag()

    const center = ref({ lat: 65, lng: 12 })

    onMounted(() => {
      if (props.location) {
        center.value = props.location
      }
    })
    const zoom = ref(4)

    const close = (event) => {
      emit("close")
    }
    console.log(route.path)
    const mapclick = (e) => {
      console.log(e)
      center.value = { lat: e.latLng.lat(), lng: e.latLng.lng() }
    }
    const adress = ref("")
    const lookupaddress = async () => {
      await checkToken()
      console.log("test")

      axios({
        baseURL: apiurl,
        url: `/lookup/${adress.value}`,
        data: {},
        method: "POST",
        headers: {
          Authorization: "Bearer " + token.value,
        },
      })
        .then((resp) => {
          console.log(resp.data.location)
          if (resp.data.location) {
            console.log("test")

            center.value = resp.data.location
            zoom.value = 8
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }

    return {
      close,
      adress,
      store,
      zoom,
      center,
      mapclick,
      lookupaddress,
    }
  },
})
</script>
<style lang="scss" scoped>
@import "../css/home.scss";

.container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.password {
  height: 0px;
  opacity: 0;
  display: none;
}
.fade-enter-active {
  transition: opacity 1s;
}
.fade-enter-from .subscribe {
  opacity: 0;
}
.fade-enter-to .subscribe {
  opacity: 1;
}
.fade-enter-active .subscribe {
  transition: opacity 500ms 500ms;
}
.fade-enter-from .blur {
  opacity: 0;
  backdrop-filter: blur(0px);
}
.fade-enter-to .blur {
  opacity: 1;
  backdrop-filter: blur(6px);
}
.fade-enter-active .blur {
  transition: backdrop-filter 500ms;
}

.remodal-content-wrapper {
  background-color: #faf9f8;
  box-sizing: border-box;
  padding: 64px 24px 32px;
  max-width: 500px;

  .remodal-close {
    height: 22px;
    overflow: hidden;
    position: absolute;
    right: 21px;
    text-indent: -9999px;
    top: 21px;
    width: 22px;

    &::before {
      background-image: url(data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%3Ctitle%3EFoundation%20%2F%20Icon%20%2F%2024%20%2F%20Control%20%2F%20Close%3C%2Ftitle%3E%3Cdefs%3E%3Cpath%20d%3D%22M12%2011.29L21.29%202l.71.71L12.71%2012%2022%2021.29l-.71.71L12%2012.71%202.71%2022%202%2021.29%2011.29%2012%202%202.71%202.71%202%2012%2011.29z%22%20id%3D%22prefix__a%22%2F%3E%3C%2Fdefs%3E%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Cpath%20d%3D%22M0%200h24v24H0z%22%2F%3E%3Cuse%20fill%3D%22%23222%22%20xlink%3Ahref%3D%22%23prefix__a%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 20px;
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
  .remodal-content,
  .remodal-footer {
    background-color: #faf9f8;
    text-align: center;
  }
  .remodal-content {
    .header {
      text-align: center;
      margin-bottom: 48px;

      .heading {
        font-size: 20px;
        line-height: 24px;
        background-position: top center;
        background-size: 90px;
        margin: 0 0 12px;
        text-align: center;
      }
      .excerpt {
        margin: 0 0 20px;
      }
    }
    form {
      text-align: left;

      .inputwrapper {
        margin: 13px 0;
        max-width: 100%;
      }
    }
  }
}
</style>
